/* Home.css */
.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.bio {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 800px;
    margin-bottom: 20px;
}

.bio img {
    width: 150px;
    border-radius: 50%;
    margin-right: 20px;
}

.links a {
    margin-right: 10px;
    text-decoration: none;
    color: #007bff;
}
